import { compactFormatter } from "@/Utils/NumberHandling/compactFormatter";
import { stonkClient } from "@/Utils/stonkClient";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
// const ETHUSD =
//   "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace";
const useEthPrice = () => {
  // const { data } = useSWR("ETH-Price", {
  //   fetcher: async () => {
  //     //   const results = await axios.get(
  //     //     `https://bufferf-pythnet-4e5a.mainnet.pythnet.rpcpool.com/b6f0d8f7-97c2-4bb4-953c-5405fc9cd0ae/api/latest_price_feeds?ids[]=${ETHUSD}`
  //     //   );
  //     const coingekores = await axios.get(
  //       `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`
  //     );
  //     return coingekores.data.ethereum.usd;
  //     //   console.log(`results.data: `, results.data);
  //     //   const price = BigInt(results.data[0].price.price);
  //     //   return absoluteInt(price, -results.data[0].price.expo);
  //   },
  //   refreshInterval: 500 * 1000,
  // });

  const { data } = useQuery({
    queryKey: ["eth-price"],
    queryFn: async () => {
      const res = await stonkClient("eth_price/");
      return (res.data.data.ethereum_price_usd as number) || 0;
    },
    initialData: 3500,
    refetchInterval: import.meta.env.VITE_DISABLE_POOLING ? undefined : 10000,
    staleTime: 10000,
  });

  return data;
};

export const useEthConversion = () => {
  const ethPrice = useEthPrice();
  const usd = useCallback(
    (ethAmount: number | string) => {
      if (ethPrice) return +ethPrice * +ethAmount;
      else return 0;
    },
    [ethPrice]
  );
  const compactUSD = (eth: string | number) => "$" + compactFormatter(usd(eth));
  return { usd, compactUSD };
};

export default useEthPrice;
