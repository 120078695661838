import {
  useAccount,
  useChainId,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from "wagmi";
const countreAbi = [
  {
    type: "function",
    name: "current",
    inputs: [],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "decrement",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "increment",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "reset",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    name: "Count",
    inputs: [
      {
        name: "",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
] as const;

export const Test = () => {
  const network = useChainId();
  const walletClient = useWalletClient({ chainId: 99888 });
  const publicClient = usePublicClient({ chainId: 99888 });
  console.log("network", network);
  const { writeContractAsync } = useWriteContract();
  const account = useAccount();
  const call = async () => {
    const sim = await publicClient?.simulateContract({
      abi: countreAbi,
      address: "0xF461d09EB295f1538a6fec92072eB2F3578e121a",
      functionName: "increment",
      args: [],
      account: account.address,
    });
    console.log("critical-log:2", sim);
    const hash = await walletClient.data?.writeContract(sim?.request!);
    console.log("critical-log:3", hash);
    const transaction = await publicClient?.waitForTransactionReceipt({
      hash: hash!,
    });
    console.log("critical-log:5", transaction);
  };
  return (
    <div>
      <button onClick={call} className="text-[30px] text-[#fff]">
        Click me
      </button>
    </div>
  );
};
