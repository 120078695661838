import { stonkClient } from "@/Utils/stonkClient";
import { UserData } from "@/types/BaseTypes";
import { useQuery } from "@tanstack/react-query";

const useUser = (userAddress?: string) => {
  return useQuery({
    queryKey: ["user", userAddress],
    queryFn: async () => {
      const res = await stonkClient(`user/${userAddress}/profile/`);
      return res.data.data as UserData;
    },
    enabled: !!userAddress,
  });
};

export default useUser;
