import { EventData } from "@/types/BaseTypes";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import Activity from "./Activity";

const LiveActivity = () => {
  const [activityData, setActivityData] = useState<EventData[]>([]);

  useEffect(() => {
    const websocket = new WebSocket(
      `${import.meta.env.VITE_API_WS_URL}/ws/timeline`
    );
    websocket.onmessage = (event) => {
      const events = (JSON.parse(event.data) as EventData[]).filter(
        (event) => event.eventType !== "Comment"
      );
      setActivityData((prev) => [...events, ...prev]);
    };
    return () => {
      websocket.close();
    };
  }, []);

  return (
    <div className="flex h-8 items-center gap-2 rounded-sm bg-b1 p-2">
      <div className="h-2 w-2 min-w-2 animate-pulse rounded-full bg-accent shadow-lg duration-700"></div>
      <div className="text-nowrap text-md">
        Live
        <ArrowRightIcon className="inline" />
      </div>
      <div className="scroll-hidden flex gap-2 overflow-x-auto text-md text-secondary">
        {activityData.map((eventData, i) => (
          <div
            key={i}
            className="last flex min-w-fit items-center gap-1 text-nowrap border-r border-subtext pr-2 last:border-0"
          >
            <Activity data={eventData} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiveActivity;
