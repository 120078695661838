import { routerAbi } from "@/ABIs/Router";
import { Contracts } from "@/ContractsConfig";
import { Log, decodeEventLog } from "viem";

export const decodeLogs = (logs: Log<bigint, number, false>[]) => {
  const data: ReturnType<typeof decodeEventLog>[] = [];
  logs.forEach((log) => {
    if (
      log.address.toLocaleLowerCase() === Contracts.router.toLocaleLowerCase()
    ) {
      data.push(
        decodeEventLog({
          abi: routerAbi,
          data: log.data,
          topics: log.topics,
        })
      );
    }
  });
  return data;
};

export const decodeCreateTokenEvent = (logs: Log<bigint, number, false>[]) => {
  for (const log of logs) {
    try {
      const decoded = decodeEventLog({
        abi: routerAbi,
        eventName: "CreateToken",
        data: log.data,
        topics: log.topics,
      });
      if (decoded.eventName === "CreateToken") {
        return decoded;
      }
    } catch (e) {
      // do nothing
    }
  }
};
