import { stonkClient } from "@/Utils/stonkClient";
import { TokenHeld, TokenHeldProps } from "@/components/TokenCards";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import React from "react";

type Props = {
  userAddress: `0x${string}`;
};

const TokensHeld = ({ userAddress }: Props) => {
  const { data, loadMoreRef, isLoading, isFetchingNextPage } =
    useInfiniteScroll({
      queryKey: ["user", userAddress, "tokens_held"],
      queryFn: async ({ pageParam }) => {
        const res = await stonkClient(`user/${userAddress}/tokens_held/`, {
          params: { page: pageParam },
        });
        return res.data.data as TokenHeldProps[];
      },
    });

  return (
    <div className="flex flex-col items-center gap-5 pb-5">
      <div className="flex w-full flex-col gap-4">
        {!!data &&
          data.pages.map((group, i) => (
            <React.Fragment key={i}>
              {group.map((token) => (
                <TokenHeld
                  key={token.tokenId}
                  name={token.name}
                  address={token.tokenId}
                  tokenId={token.tokenId}
                  ticker={token.ticker}
                  token_image={token.token_image}
                  token_image_is_nsfw={token.token_image_is_nsfw}
                  balance={token.balance}
                  claimedRefecltionAmount={token.claimedRefecltionAmount}
                  claimableReflectionAmount={token.claimableReflectionAmount}
                  soldInBondingCurve={token.soldInBondingCurve}
                  curveType={token.curveType}
                />
              ))}
            </React.Fragment>
          ))}
      </div>
      <div ref={loadMoreRef} id="load-more" style={{ height: 1 }} />
      {isFetchingNextPage && <p>Loading more...</p>}
      {isLoading && !isFetchingNextPage && <p>Loading...</p>}
      {!!data && (data.pages.length === 0 || data?.pages[0].length === 0) && (
        <p>Nothing here yet</p>
      )}
    </div>
  );
};

export default TokensHeld;
