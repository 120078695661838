import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  coinbaseWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { QueryClient } from "@tanstack/react-query";
import { createConfig } from "@wagmi/core";
import { base } from "@wagmi/core/chains";
import { createPublicClient, defineChain, http } from "viem";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 1000,
    },
  },
});

export const customAnvil = defineChain({
  id: +import.meta.env.VITE_CHAIN_ID,
  name: "HyperLiquid Stonk",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: [import.meta.env.VITE_RPC_URL],
      // webSocket: [import.meta.env.VITE_RPC_WS_URL],
    },
  },
});
export const HyperLiquid =  defineChain({
  id: 998,
  name: 'Hyperliquid-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Test Hyper',
    symbol: 'TESTH',
  },
  rpcUrls: {
    default: {
      http: ['https://api.hyperliquid-testnet.xyz/evm'],
    },
  },
  contracts: {
    multicall3: {
      address: '0x0eb1A99ACbfA4bEDCfd3F25971963408de88DC5b',
    },
  },
})


export const publicClient = createPublicClient({
  chain: HyperLiquid,
  transport: http(),
});


const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [
        metaMaskWallet,
        walletConnectWallet,
        coinbaseWallet,
        rainbowWallet,
      ],
    },
  ],
  {
    appName: "Stonk.social",
    projectId: import.meta.env.VITE_PROJECT_ID,
  }
);

export const config = createConfig({
  chains: [customAnvil],
  connectors,
  transports: {
    // [base.id]: http(),
    [customAnvil.id]: http(),
  },
});
