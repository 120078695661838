import { getGraduationMarketCap } from "@/Utils/PlatformMath/getTokenomics";
import { getDisplayDateTime } from "@/Utils/displayDateTime";
import InfoIcon from "@/assets/InfoIcon";
import { FormatEthAmount, FormatTokenAmount } from "@/components/FormatAmount";
import { MemeCard } from "@/components/MemeCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";
import { Progress } from "@/components/ui/progress";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { TokenData, TokenSettings } from "@/types/BaseTypes";
import { GlobeIcon, TwitterLogoIcon } from "@radix-ui/react-icons";
import { FaTelegramPlane } from "react-icons/fa";
import Holders from "./Holders";
import KOLAllocation from "./KOLAllocation";
type Props = {
  tokenData: TokenData & TokenSettings;
};
const Info = ({ tokenData }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <MemeCard
        variant="descriptive"
        className="w-fit min-w-full"
        token={tokenData}
        noLink
        linkCreator
        noOutline
      />

      <div className="flex gap-6">
        {!!tokenData.twitter && (
          <a
            className="text-secondary-foreground underline"
            href={tokenData.twitter}
            target="_blank"
          >
            <TwitterLogoIcon className="mr-1 inline h-4 w-4" />
            Twitter
          </a>
        )}
        {!!tokenData.telegram && (
          <a
            className="text-secondary-foreground underline"
            href={tokenData.telegram}
            target="_blank"
          >
            <FaTelegramPlane className="mr-1 inline h-4 w-4" />
            Telegram
          </a>
        )}
        {!!tokenData.website && (
          <a
            className="text-secondary-foreground underline"
            href={tokenData.website ? tokenData.website : ""}
            target="_blank"
          >
            <GlobeIcon className="mr-1 inline h-4 w-4" />
            Website
          </a>
        )}
      </div>

      <div>
        <div className="mb-2 text-md text-secondary-foreground">
          Bonding curve progress{" "}
          {Number(
            (
              (tokenData.soldInBondingCurve / tokenData.graduationTokenAmount) *
              100
            ).toFixed(2)
          )}
          %{" "}
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <InfoIcon fill="#aaaaaa" />
              </TooltipTrigger>
              <TooltipContent className="max-w-96 p-4">
                <div className="text-sm text-secondary-foreground">
                  When the market cap reaches{" "}
                  <FormatEthAmount
                    eth={getGraduationMarketCap(
                      tokenData.graduationTokenAmount,
                      tokenData.curveType
                    )}
                  />{" "}
                  Ξ all the liquidity from the bonding curve will be deposited
                  into Uniswap and burned. Progression increases as the price
                  goes up.
                </div>
                <div className="text-sm text-secondary-foreground">
                  There are{" "}
                  <FormatTokenAmount
                    token={
                      tokenData.graduationTokenAmount -
                      tokenData.soldInBondingCurve
                    }
                  />{" "}
                  tokens still available for sale in the bonding curve and there
                  is <FormatEthAmount eth={tokenData.bondingCurveETH} /> Ξ in
                  the bonding curve.
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <Progress
          value={
            (tokenData.soldInBondingCurve / tokenData.graduationTokenAmount) *
            100
          }
        />
        <p className="my-1 text-sm text-secondary-foreground">
          <FormatTokenAmount
            token={
              tokenData.graduationTokenAmount - tokenData.soldInBondingCurve
            }
          />{" "}
          tokens away from graduation!
        </p>

        {tokenData.ultraStonkStart &&
          (tokenData.ultraStonkEnd ? (
            <div className="text-md">
              Was <span className="text-accent">Ultra Stonk</span> between{" "}
              {getDisplayDateTime(tokenData.ultraStonkStart)} and{" "}
              {getDisplayDateTime(tokenData.ultraStonkEnd)}
            </div>
          ) : (
            <div className="text-md">
              Became <span className="text-accent">Ultra Stonk</span> on{" "}
              {getDisplayDateTime(tokenData.ultraStonkStart)}
            </div>
          ))}
        {!tokenData.ultraStonkStart && tokenData.marketCap !== undefined && (
          <>
            <div className="mb-2 text-md text-secondary-foreground">
              Ultra Stonk progress{" "}
              {Number(((tokenData.marketCap / 5e18) * 100).toFixed(2))}%
            </div>
            <Progress value={(tokenData.marketCap / 5e18) * 100} />
            <p className="text-sm text-secondary-foreground">
              Becomes Ultra Stonk at 5Ξ Market cap
            </p>
          </>
        )}
      </div>
      <Tabs defaultValue="holders" className="w-full">
        <TabsList>
          <TabsTrigger value="holders">Holder Distribution</TabsTrigger>
          <TabsTrigger value="kol">KOL Allocation</TabsTrigger>
        </TabsList>
        <div className="pt-3">
          <TabsContent value="holders">
            <Holders
              tokenAddress={tokenData.id}
              tokenCreated={tokenData.created}
            />
          </TabsContent>
          <TabsContent value="kol">
            <KOLAllocation tokenAddress={tokenData.id} tokenData={tokenData} />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
};

export default Info;
