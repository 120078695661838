import { stonkClient } from "@/Utils/stonkClient";
import { TokenAllocated } from "@/components/TokenCards";
import { Button } from "@/components/ui/button";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import React from "react";

type Props = {
  userAddress: `0x${string}`;
  isOwner: boolean;
  setTab: (arg: string) => void;
};

const TokensAllocated = ({ userAddress, isOwner, setTab }: Props) => {
  const { data, loadMoreRef, isLoading, isFetchingNextPage } =
    useInfiniteScroll({
      queryKey: ["user", userAddress, "tokens_allocated"],
      queryFn: async ({ pageParam }) => {
        const res = await stonkClient(`user/${userAddress}/tokens_allocated/`, {
          params: { page: pageParam },
        });
        return res.data.data;
      },
    });

  return (
    <div className="flex flex-col items-center gap-5 pb-5">
      <div className="flex w-full flex-col gap-4">
        {!!data &&
          data.pages.map((group, i) => (
            <React.Fragment key={i}>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {group.map((token: any) => (
                <TokenAllocated
                  key={token.id}
                  userAddress={userAddress}
                  isOwner={isOwner}
                  tokenAddress={token.tokenId}
                  tokenAmount={token.tokenAmount}
                  claimedAmount={token.claimedAmount}
                  token_image={token.token_image}
                  username={token.username}
                  ticker={token.ticker}
                  soldInBondingCurve={token.soldInBondingCurve}
                  token_image_is_nsfw={token.token_image_is_nsfw}
                  curveType={token.curveType}
                  graduationTokenAmount={token.graduationTokenAmount}
                  hasGraduated={token.hasGraduated}
                />
              ))}
            </React.Fragment>
          ))}
      </div>
      <div ref={loadMoreRef} id="load-more" style={{ height: 1 }} />
      {isFetchingNextPage && <p>Loading more...</p>}
      {isLoading && !isFetchingNextPage && <p>Loading...</p>}
      {!!data && (data.pages.length === 0 || data?.pages[0].length === 0) && (
        <p>No tokens allocated</p>
      )}
      {isOwner && (
        <div className="my-8 flex flex-wrap items-center justify-center gap-4">
          <span>Link social accounts</span>
          <Button
            variant={"outline"}
            size={"md"}
            className="p-4 font-normal"
            onClick={() => setTab("verified-socials")}
          >
            Connect Socials
          </Button>
        </div>
      )}
    </div>
  );
};

export default TokensAllocated;
