import pumpLogo from "@/assets/pumpLogo.png";
import starShining from "@/assets/starShining.webp";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { CoinIdea } from "@/types/BaseTypes";
import { stonkClient } from "@/Utils/stonkClient";
import { useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type Props = {
  isMobile: boolean;
};
const IdeasTab = ({ isMobile }: Props) => {
  const navigate = useNavigate();
  const [ideaDialogOpen, setIdeaDialogOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    name: "",
    ticker: "",
    description: "",
    image: "",
  });

  useEffect(() => {
    if (!isMobile) {
      navigate("/ideas/create");
    }
  }, [isMobile]);

  const { data } = useQuery({
    queryKey: ["pumpCoins"],
    queryFn: async () => {
      const res = await stonkClient("ideas/");
      return res.data.data as CoinIdea[];
    },
    refetchInterval: import.meta.env.VITE_DISABLE_POOLING ? undefined : 10000,
  });

  const getCoinIdeaFromURL = async (url: string) => {
    const res = await stonkClient("ideas/query/", {
      params: { url },
    });
    return res.data.data as CoinIdea;
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex rounded-md border border-border p-3">
        <img src={starShining} className="h-20 w-20 rounded-sm" />
        <div className="flex-auto">
          <p className="text-md">
            Copy successful coin launches from popular platforms
          </p>
          <p className="text-md text-secondary-foreground">
            Use the ideas below 👇
          </p>

          <Dialog open={ideaDialogOpen} onOpenChange={setIdeaDialogOpen}>
            <DialogTrigger>
              <p className="text-md text-secondary-foreground">
                or <span className="underline">[Paste a URL]</span>
                <img src={pumpLogo} className="inline h-4 w-4" />
              </p>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle className="text-md">
                  Create token from URL
                </DialogTitle>
                <DialogDescription>
                  Enter URL of the Pump.fun coin you want to create.
                </DialogDescription>
              </DialogHeader>
              <Formik
                initialValues={{ url: "" }}
                onSubmit={async (values, { setFieldError }) => {
                  try {
                    const coinIdea = await getCoinIdeaFromURL(values.url);
                    setSearchParams({
                      name: coinIdea.name,
                      ticker: coinIdea.ticker,
                      description: coinIdea.description,
                      image: coinIdea.image,
                    });
                    setIdeaDialogOpen(false);
                  } catch (e) {
                    setFieldError("url", "Error. Check if the URL is valid.");
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="flex items-center gap-2">
                      <Input
                        name="url"
                        type="text"
                        placeholder="Paste URL here"
                        className="flex-auto rounded-md border border-border p-2 text-md"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.url}
                      />
                      <Button
                        type="submit"
                        size="md"
                        className="rounded-sm"
                        disabled={isSubmitting}
                      >
                        Create
                      </Button>
                    </div>
                    <p className="text-sm text-red">
                      {errors.url && touched.url && errors.url}
                    </p>
                  </form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      {data?.map((coin) => (
        <div key={coin.address} className="rounded-md border border-border p-3">
          <div className="flex items-center gap-3">
            <img
              src={coin.image}
              alt={coin.name}
              className="h-20 w-20 rounded-sm"
            />
            <div className="flex-auto">
              <p className="">{coin.name}</p>
              <p className="flex gap-2 text-md text-accent">
                ${coin.ticker}{" "}
                <a href={coin.url} target="_blank">
                  <span>
                    <img src={pumpLogo} className="h-4 w-4" />
                  </span>
                </a>
              </p>
              <div className="flex flex-wrap items-center justify-between gap-2">
                <div className="w-fit rounded-sm bg-accent-green/20 px-2 py-1 text-sm text-accent-green">
                  <p className="">${coin.market_cap_usd?.toFixed(0)} Mcap</p>
                  <p className="">{coin.total_comments} Replies</p>
                </div>
                <Button
                  size="md"
                  className="rounded-sm"
                  onClick={() => {
                    navigate(
                      `/ideas/create?${new URLSearchParams({
                        name: coin.name,
                        ticker: coin.ticker,
                        description: coin.description,
                        image: coin.image,
                      }).toString()}`
                    );
                  }}
                >
                  Create
                </Button>
              </div>
            </div>
          </div>
          <p className="mt-2 text-md text-secondary-foreground">
            {coin.description.length < 120
              ? coin.description
              : coin.description.slice(0, 120) + "..."}
          </p>
        </div>
      ))}
    </div>
  );
};

export default IdeasTab;
