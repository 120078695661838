import { stonkClient } from "@/Utils/stonkClient";
import { TokenComment, TokenCommentProps } from "@/components/TokenCards";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import React from "react";

type Props = {
  userAddress: `0x${string}`;
};

const Replies = ({ userAddress }: Props) => {
  const { data, loadMoreRef, isLoading, isFetchingNextPage } =
    useInfiniteScroll({
      queryKey: ["user", userAddress, "comments"],
      queryFn: async ({ pageParam }) => {
        const res = await stonkClient(`user/${userAddress}/comments/`, {
          params: { page: pageParam },
        });
        return res.data.data as TokenCommentProps[];
      },
    });

  return (
    <div className="flex flex-col items-center gap-5 pb-5">
      <div className="flex w-full flex-col gap-4">
        {!!data &&
          data.pages.map((group, i) => (
            <React.Fragment key={i}>
              {group.map((comment) => (
                <TokenComment
                  key={comment.id}
                  comment={comment.comment}
                  comment_image={comment.comment_image}
                  comment_image_is_nsfw={comment.comment_image_is_nsfw}
                  user_profile_image={comment.user_profile_image}
                  user_image_is_nsfw={comment.user_image_is_nsfw}
                  user_address={comment.user_address}
                  token_address={comment.token_address}
                  name={comment.name}
                  ticker={comment.ticker}
                  id={comment.id}
                  total_likes={comment.total_likes}
                />
              ))}
            </React.Fragment>
          ))}
      </div>
      <div ref={loadMoreRef} id="load-more" style={{ height: 1 }} />
      {isFetchingNextPage && <p>Loading more...</p>}
      {isLoading && !isFetchingNextPage && <p>Loading...</p>}
      {!!data && data.pages.length && data?.pages[0].length === 0 && (
        <p>Nothing here yet</p>
      )}
    </div>
  );
};

export default Replies;
