import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";
import { DialogDescription } from "@radix-ui/react-dialog";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function HowItWorks({ open, setOpen }: Props) {
  return (
    <Dialog open={open} onOpenChange={(s) => setOpen(s)}>
      <DialogContent className="px-10 sm:max-w-lg md:max-w-xl">
        <DialogHeader>
          <DialogTitle className="text-center">
            Stonk lets you launch, discover, and ape into the next 1000x coins!
          </DialogTitle>
          <DialogDescription className="text-center text-sm text-secondary-foreground">
            All launches are fair with No pre-sale, no insiders, and with a
            fixed 1B supply
          </DialogDescription>
        </DialogHeader>
        <div>
          <Tabs defaultValue="launch" className="w-full">
            <TabsList className="flex justify-center">
              <TabsTrigger value="launch">Launch a coin</TabsTrigger>
              <TabsTrigger value="trade">Trade a coin</TabsTrigger>
            </TabsList>
            <div className="p-3 text-[14px]">
              <TabsContent value="launch" tabIndex={undefined}>
                <ol className="flex list-decimal flex-col gap-2 pl-5">
                  <li>Launch a token for free, with zero gas fee</li>
                  <li>
                    Divide reserved tokens (~25% of supply) among multiple KOLs
                    by directly allocating tokens to their social account
                  </li>
                  <li>
                    If the market cap reaches-70k the token is listed on Uniswap
                  </li>
                  <li>
                    All the liquidity added to Uniswap is burnt making rug pull
                    impossible
                  </li>
                  <li>
                    Receive 0.05 ETH or higher, if the token graduates to
                    Uniswap
                  </li>
                </ol>
              </TabsContent>
              <TabsContent value="trade" tabIndex={undefined}>
                <ol className="flex list-decimal flex-col gap-2 pl-5">
                  <li>Pick and buy any token you like</li>
                  <li>Sell anytime to book your profits</li>
                  <li>
                    Receive 0.05 ETH or higher, If your buy trade graduates the
                    token to Uniswap
                  </li>
                </ol>
              </TabsContent>
            </div>
          </Tabs>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-center">
            <DialogClose asChild>
              <Button type="button" variant="default" size="md">
                Explore Stonks
              </Button>
            </DialogClose>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
export default HowItWorks;
