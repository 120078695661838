import { getTokenCurrentPrice } from "@/Utils/PlatformMath/getTokenomics";
import { FormatEthAmount, FormatTokenAmount } from "@/components/FormatAmount";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import useContract from "@/hooks/useContract";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import Image from "./Image";
import { Button } from "./ui/button";

export type TokenHeldProps = {
  name: string;
  tokenId: string;
  address: string;
  ticker: string;
  token_image: string;
  token_image_is_nsfw: boolean;
  balance: number;
  claimableReflectionAmount: number;
  claimedRefecltionAmount: number;
  soldInBondingCurve: number;
  curveType: 0 | 1 | 2;
};
export const TokenHeld = (props: TokenHeldProps) => {
  return (
    <div className="flex items-center gap-3 rounded-xl border border-[#2F3336] p-3">
      <Link to={`/coins/${props.address}`}>
        <Image
          variant="token"
          src={props.token_image}
          alt="Token image"
          isNsfw={props.token_image_is_nsfw}
          className="h-16 w-16 rounded-sm"
        />
      </Link>
      <div>
        <Link to={`/coins/${props.address}`}>
          <p className="font-inter text-[15px] font-medium text-[#E7E9EA]">
            {props.name} Token
          </p>
        </Link>
        <p className="font-roboto-mono text-[15px] font-normal text-[#FBC025]">
          <FormatTokenAmount token={props.balance} /> ${props.ticker} (
          <FormatEthAmount
            eth={
              getTokenCurrentPrice(props.soldInBondingCurve, props.curveType) *
              props.balance
            }
          />{" "}
          Ξ)
        </p>
        {/* <p className="font-roboto-mono text-[15px] font-normal text-[#71767B]">
          Reflection Rewards:{" "}
          <FormatEthAmount
            eth={
              props.claimableReflectionAmount + props.claimedRefecltionAmount
            }
          />{" "}
          Ξ
        </p> */}
      </div>
    </div>
  );
};

export type TokenAllocatedProps = {
  userAddress: `0x${string}`;
  isOwner: boolean;
  tokenAddress: `0x${string}`;
  tokenAmount: number;
  claimedAmount: number;
  username: string;
  token_image: string;
  token_image_is_nsfw: boolean;
  ticker: string;
  soldInBondingCurve: number;
  curveType: 0 | 1 | 2;
  graduationTokenAmount: number;
  hasGraduated: boolean;
};
export const TokenAllocated = (props: TokenAllocatedProps) => {
  const queryClient = useQueryClient();
  const { callContract } = useContract({
    toastTitle: "Claiming allocated token",
    onConfirmDelayed: () => {
      queryClient.invalidateQueries({
        queryKey: ["user", props.userAddress, "tokens_allocated"],
      });
    },
  });

  const alreadyClaimed =
    props.hasGraduated && props.claimedAmount === props.tokenAmount;

  return (
    <div className="flex items-center gap-3 rounded-xl border border-[#2F3336] p-3">
      <Link to={`/coins/${props.tokenAddress}`}>
        <Image
          variant="token"
          src={props.token_image}
          alt="Token image"
          isNsfw={props.token_image_is_nsfw}
          className="h-16 w-16"
        />
      </Link>
      <div>
        <Link to={`/coins/${props.tokenAddress}`}>
          <p className="font-inter text-[15px] font-medium text-[#E7E9EA]">
            ${props.ticker} Token
          </p>
        </Link>
        <p className="font-roboto-mono text-[15px] font-normal text-[#FBC025]">
          Allocated: <FormatTokenAmount token={props.tokenAmount} /> $
          {props.ticker} (
          <FormatEthAmount
            eth={
              getTokenCurrentPrice(props.soldInBondingCurve, props.curveType) *
              props.tokenAmount
            }
          />{" "}
          Ξ)
        </p>
        <p className="font-roboto-mono text-[15px] font-normal text-[#FBC025]">
          Claimed: <FormatTokenAmount token={props.claimedAmount} /> $
          {props.ticker} (
          <FormatEthAmount
            eth={
              getTokenCurrentPrice(props.soldInBondingCurve, props.curveType) *
              props.claimedAmount
            }
          />{" "}
          Ξ)
        </p>
        {/* <p className="text-[#71767B] text-[15px] font-normal font-roboto-mono">
          @KakarotBuilds
        </p> */}
      </div>
      {props.isOwner &&
        (props.hasGraduated ? (
          <Button
            variant={"outline"}
            size={"md"}
            className="ml-auto mr-4"
            onClick={() =>
              callContract("claimKOLAllocation", [props.tokenAddress])
            }
            disabled={alreadyClaimed}
          >
            {alreadyClaimed ? "Claimed" : "Claim"}
          </Button>
        ) : (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <Button
                  variant={"outline"}
                  size={"md"}
                  className="ml-auto mr-4"
                  disabled={true} // disable when not graduated
                >
                  "Claim"
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>"You can only claim it after the coin is graduated."</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
    </div>
  );
};

export type TokenCommentProps = {
  comment: string;
  comment_image: string;
  user_profile_image: string;
  user_address: `0x${string}`;
  token_address: string;
  name: string;
  ticker: string;
  id: number;
  total_likes: number;
  user_image_is_nsfw: boolean;
  comment_image_is_nsfw: boolean;
};

export const TokenComment = (props: TokenCommentProps) => {
  return (
    <div className="flex gap-3 rounded-xl border border-[#2F3336] p-3">
      <Link to={`/coins/${props.token_address}`} className="shrink-0">
        <Image
          variant="profile"
          src={props.user_profile_image}
          alt="Token image"
          isNsfw={props.user_image_is_nsfw}
          address={props.user_address}
          className="h-10 w-10 rounded-full"
        />
      </Link>
      <div className="flex flex-col gap-2">
        <Link to={`/coins/${props.token_address}`}>
          <p className="text-[15px] font-medium">
            {props.name} <span className="text-[#FBC025]">${props.ticker}</span>
          </p>
        </Link>
        {props.comment_image && (
          <div className="max-w-24 overflow-hidden rounded-xl">
            <Image
              variant="comment"
              className="object-cover object-center"
              src={props.comment_image}
              alt={"Comment image"}
              isNsfw={props.comment_image_is_nsfw}
            />
          </div>
        )}
        <p className="font-roboto-mono text-md font-normal text-secondary-foreground">
          {props.comment}
        </p>
      </div>
    </div>
  );
};
