import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useEthConversion } from "@/hooks/useEthPrice";
import { cn } from "@/lib/utils";
import { TokenData } from "@/types/BaseTypes";
import { Link } from "react-router-dom";
import Image from "./Image";

type TokenCardProps = {
  variant?: "descriptive" | "compact" | "default";
  className?: string;
  noOutline?: boolean;
  noLink?: boolean;
  linkCreator?: boolean;
  token: TokenData;
};

const MemeCard = ({
  token,
  variant = "default",
  className = "",
  noLink = false,
  noOutline = false,
  linkCreator = false,
}: TokenCardProps) => {
  const { compactUSD } = useEthConversion();

  const component = (
    <div>
      <div
        className={cn(
          "gap-y-1 rounded-2xl p-2",
          className,
          variant == "compact" && "bg-[#14161A]",
          !noOutline && "border-[1px] border-border"
        )}
      >
        <div className="flex">
          <Image
            variant="token"
            src={token.token_image}
            alt={token.name}
            isNsfw={token.token_image_is_nsfw}
            className="h-16 w-16 rounded-2xl object-cover"
          />
          <div className="ml-2 flex w-full min-w-0 flex-col">
            <p className="text-[12px] text-[#bfdbfe]">
              Created by
              {linkCreator ? (
                <Link to={`/people/${token.creator_address}`}>
                  <span className="text-link"> @{token.creator_username}</span>
                </Link>
              ) : (
                <span className="text-link"> @{token.creator_username}</span>
              )}
            </p>
            <div className="flex flex-wrap gap-x-2">
              <h1 className="truncate text-md font-medium text-[#e7e9ea]">
                {token.name}
              </h1>
              {/* {variant === "descriptive" && token?.created_at && (
                <span className="ml-auto text-sm text-subtext">
                  <TimeElapsed eventTime={new Date(token?.created_at * 1000)} />
                </span>
              )} */}
              <h2 className="text-md text-accent">${token.ticker}</h2>
            </div>

            {variant == "default" || variant == "descriptive" ? (
              <>
                {" "}
                <div className="text-sm text-[#86efac]">
                  <span>
                    Market cap: {compactUSD((token.marketCap || 0) / 1e18)}{" "}
                    {!!token.graduationTokenAmount &&
                      !!token.soldInBondingCurve &&
                      "(" +
                        Number(
                          (token.soldInBondingCurve /
                            token.graduationTokenAmount) *
                            100
                        ).toFixed(2) +
                        "%)"}
                  </span>
                  {/* <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <span className="inline-block h-3 w-7 px-1">
                          {token.curveType === 0 && (
                            <img
                            src="/curve/[I].png"
                            alt="[I]"
                          />
                          )}
                          {token.curveType === 1 && (
                            <img
                            src="/curve/[II].png"
                            alt="[II]"
                          />
                          )}
                          {token.curveType === 2 && (
                            <img
                              src="/curve/[III].png"
                              alt="[III]"
                            />
                          )}
                        </span>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          {{ 0: "Hodl", 1: "Moon", 2: "Mars" }[token.curveType]}{" "}
                          Curve
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider> */}

                  {!!token.isUltraStonk && (
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                          <span className="text-sm">👑</span>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Ultra Stonk</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                  {!!token.graduationTokenAmount &&
                    !!token.soldInBondingCurve &&
                    token.soldInBondingCurve >= token.graduationTokenAmount && (
                      <TooltipProvider>
                        <Tooltip delayDuration={0}>
                          <TooltipTrigger asChild>
                            <span className="text-sm">🦄</span>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Coin graduated</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                </div>
                <div className="text-sm text-[#86efac]">
                  Comments: {token.total_comments}, Holders:{" "}
                  {token.total_holders}
                </div>
              </>
            ) : null}
          </div>
        </div>
        {variant === "descriptive" ? (
          <p className="mt-1 truncate text-wrap text-[15px] leading-snug text-[#9ca3af] [-webkit-box-orient:vertical] [-webkit-line-clamp:4] [display:-webkit-box]">
            {token.description}
          </p>
        ) : null}
      </div>
    </div>
  );

  return (
    <>
      {noLink ? component : <Link to={`/coins/${token?.id}`}>{component}</Link>}
    </>
  );
};
export { MemeCard };
