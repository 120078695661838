import TopNav from "@/components/TopNav";
import useWallet from "@/hooks/useWallet";
import useWalletSignIn from "@/hooks/useWalletSignIn";
import Home from "@/pages/Home";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Test } from "./components/Test";

const AppRoutes = () => {
  const posthog = usePostHog();
  const wallet = useWallet();
  useWalletSignIn();

  useEffect(() => {
    if (wallet) {
      posthog?.identify(wallet.address);
      posthog?.group("loggedIn", "y");
    }
  }, [posthog, wallet?.address]);

  return (
    <BrowserRouter>
      <UIWrapper>
        <Routes>
          {/* <Route path="/token/:tokenId" element={<TokenPage />} /> */}
          {/* <Route path="/create" element={<CreateAToken />}></Route> */}
          <Route path="/test" element={<Test />} />
          <Route path="/*" element={<Home />} />
          {/* <Route
          path="/explore"
          element={
            <UIWrapper keepEmptyRightSection>
              <Explore />
            </UIWrapper>
          }
        ></Route>
        <Route
          path="/profile/:userAddress"
          element={
            <UIWrapper>
              <Profile />
            </UIWrapper>
          }
        ></Route>
        <Route
          path="/token/:tokenId"
          element={
            <UIWrapper hideSidebar hideExploreSection>
              <Token />
            </UIWrapper>
          }
        ></Route> */}
        </Routes>
      </UIWrapper>
    </BrowserRouter>
  );
};

export const UIWrapper: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  return (
    <div className="flex h-[100dvh] w-[100vw] flex-col justify-center pl-4 pt-4">
      {/* <div className="flex min-h-0 flex-auto justify-center overflow-auto"> */}
      {/* <div className={cn("flex h-fit w-full flex-col p-4")}> */}
      {/* {!props.hideSidebar ? (
            <div className="hidden w-1/4 pr-6 sm:block lg:w-1/5">
              <Sidebar />
            </div>
          ) : null} */}
      {/* <div className="flex w-3/4 flex-auto lg:w-4/5"> */}
      {/* <div className="w-full"> */}
      <div className="flex-none">
        <TopNav />
      </div>
      <div className="mt-2 h-full min-h-0 flex-auto">{props.children}</div>
      {/* </div> */}
      {/* {!props.hideExploreSection && (
              <div className="hidden w-2/5 flex-auto pl-4 lg:block">
                {!props.keepEmptyRightSection && <RightSection />}
              </div>
            )} */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* <BottomNav /> */}
    </div>
  );
};

export default AppRoutes;
