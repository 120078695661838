import { stonkClient } from "@/Utils/stonkClient";
import { MemeCard } from "@/components/MemeCard";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import { TokenData } from "@/types/BaseTypes";
import React from "react";

type Props = {
  userAddress: `0x${string}`;
};
const TokensCreated = ({ userAddress }: Props) => {
  const { data, loadMoreRef, isLoading, isFetchingNextPage } =
    useInfiniteScroll({
      queryKey: ["user", userAddress, "tokens_created"],
      queryFn: async ({ pageParam }) => {
        const res = await stonkClient(`user/${userAddress}/tokens_created/`, {
          params: { page: pageParam },
        });
        return res.data.data as TokenData[];
      },
    });

  return (
    <div className="flex flex-col items-center gap-5 pb-5">
      <div className="flex w-full flex-col gap-4">
        {!!data &&
          data.pages.map((group, i) => (
            <React.Fragment key={i}>
              {group.map((token) => (
                <MemeCard key={token.id} variant="descriptive" token={token} />
              ))}
            </React.Fragment>
          ))}
      </div>
      <div ref={loadMoreRef} id="load-more" style={{ height: 1 }} />
      {isFetchingNextPage && <p>Loading more...</p>}
      {isLoading && !isFetchingNextPage && <p>Loading...</p>}
      {!!data && (data.pages.length === 0 || data?.pages[0].length === 0) && (
        <p>Nothing here yet</p>
      )}
    </div>
  );
};

export default TokensCreated;
