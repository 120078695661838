import { atomWithStorage } from "jotai/utils";

export type Preferences = {
  howItWorksSeen: boolean;
  hideNsfw: boolean;
  peopleFilters: {
    onlyFollowing: boolean;
    sortBy: string;
    order: string;
  };
  tokenFilters: {
    onlyFollowing: boolean;
    sortBy: string;
    order: string;
  };
};

export const preferencesAtom = atomWithStorage<Preferences>(
  "stonk_preferences",
  {
    howItWorksSeen: false,
    hideNsfw: false,
    peopleFilters: {
      onlyFollowing: false,
      sortBy: '"score"',
      order: "desc",
    },
    tokenFilters: {
      onlyFollowing: false,
      sortBy: '"Token"."lastTrade"',
      order: "desc",
    },
  },
  undefined,
  {
    getOnInit: true,
  }
);
