import { atomWithStorage } from "jotai/utils";

export type Wallet = {
  address: `0x${string}`;
  signature: `0x${string}`;
  status: "connected";
};

export const walletAtom = atomWithStorage<Wallet | null>(
  "stonk_wallet",
  null,
  undefined,
  {
    getOnInit: true,
  }
);
