import * as React from "react";

function Search({
  isActive,
  ...props
}: React.SVGProps<SVGSVGElement> & { isActive?: boolean }) {
  return !isActive ? (
    <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.40625 2.34375C4.1625 2.34375 2.34375 4.1625 2.34375 6.40625C2.34375 8.65 4.1625 10.4688 6.40625 10.4688C7.52812 10.4688 8.54312 10.015 9.27875 9.27875C10.015 8.54312 10.4688 7.52812 10.4688 6.40625C10.4688 4.1625 8.65 2.34375 6.40625 2.34375ZM1.09375 6.40625C1.09375 3.4725 3.4725 1.09375 6.40625 1.09375C9.34 1.09375 11.7188 3.4725 11.7188 6.40625C11.7188 7.6475 11.2925 8.79063 10.5788 9.695L13.5669 12.6831L12.6831 13.5669L9.695 10.5788C8.79063 11.2925 7.6475 11.7188 6.40625 11.7188C3.4725 11.7188 1.09375 9.34 1.09375 6.40625Z" />
    </svg>
  ) : (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <g>
        <path d="M10.25 4.25c-3.314 0-6 2.686-6 6s2.686 6 6 6c1.657 0 3.155-.67 4.243-1.757 1.087-1.088 1.757-2.586 1.757-4.243 0-3.314-2.686-6-6-6zm-9 6c0-4.971 4.029-9 9-9s9 4.029 9 9c0 1.943-.617 3.744-1.664 5.215l4.475 4.474-2.122 2.122-4.474-4.475c-1.471 1.047-3.272 1.664-5.215 1.664-4.971 0-9-4.029-9-9z"></path>
      </g>
    </svg>
  );
}

const MemoSearch = React.memo(Search);
export default MemoSearch;
