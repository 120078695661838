import { EventData, TokenData } from "@/types/BaseTypes";
import React from "react";
import { Link } from "react-router-dom";
import { FormatEthAmount, FormatTokenAmount } from "./FormatAmount";
import Image from "./Image";

type Props = {
  data: EventData;
};

const Activity = ({ data }: Props) => {
  const tokenData: TokenData = {
    id: data.tokenAddress,
    name: data.name,
    ticker: data.ticker,
    description: data.description,
    token_image: data.token_image,
    token_image_is_nsfw: data.token_image_is_nsfw,
    creator_address: data.creator_address,
    creator_username: data.creator_username,
    total_holders: data.total_holders,
    total_comments: data.total_comments,
    curveType: data.curveType as 0 | 1 | 2,
    soldInBondingCurve: data.soldInBondingCurve,
    graduationTokenAmount: data.graduationTokenAmount,
    marketCap: data.marketCap,
    isUltraStonk: data.isUltraStonk,
    created: true,
    hash: "0x",
  };

  const [showMore, setShowMore] = React.useState(false);

  const showMoreLink = (
    <span
      className="cursor-pointer text-nowrap text-blue-600 hover:underline"
      onClick={() => setShowMore(!showMore)}
    >
      show {showMore ? "less" : "more"}
    </span>
  );

  let text: React.ReactNode;
  switch (data.eventType) {
    case "BuyAndComment":
      text = (
        <p>
          Bought <FormatTokenAmount token={data.amount} />{" "}
          <span className="text-accent">${data.ticker}</span> @{" "}
          <FormatEthAmount eth={data.price} />Ξ & Commented
        </p>
      );
      break;
    // case "Comment":
    //   text = (
    //     <p>
    //       <span className="text-secondary-foreground">Commented on</span>{" "}
    //       <span className="text-accent">${data.ticker}</span>
    //       {/* {data.metadata.length < 280 ? (
    //         data.metadata
    //       ) : (
    //         <span>
    //           {showMore ? data.metadata : data.metadata.slice(0, 280) + "..."}{" "}
    //           {showMoreLink}
    //         </span>
    //       )} */}
    //     </p>
    //   );
    //   break;
    case "CreateToken":
      text = (
        <p>
          Launched <span className="text-accent">${data.ticker}</span>
        </p>
      );
      break;
    case "CreateAndBuyToken":
      text = (
        <p>
          Launched <span className="text-accent">${data.ticker}</span> & Bought{" "}
          <FormatTokenAmount token={data.amount} />{" "}
          <span className="text-accent">${data.ticker}</span> @{" "}
          <FormatEthAmount eth={data.price} />Ξ
        </p>
      );
      break;
    case "BuyToken":
      text = (
        <p>
          Bought <FormatTokenAmount token={data.amount} />{" "}
          <span className="text-accent">${data.ticker}</span> @{" "}
          <FormatEthAmount eth={data.price} />Ξ
        </p>
      );
      break;
    case "SellToken":
      text = (
        <p>
          Sold <FormatTokenAmount token={data.amount} />{" "}
          <span className="text-accent">${data.ticker}</span> @{" "}
          <FormatEthAmount eth={data.price} />Ξ
        </p>
      );
      break;
    case "Graduated":
      text = (
        <p>
          Listed on Uniswap:{" "}
          {/* <a
            target="_blank"
            href={`https://app.uniswap.org/explore/tokens/base/${data.tokenAddress}`}
            className="break-all text-link"
          >
            {`https://app.uniswap.org/explore/tokens/base/${data.tokenAddress}`}
          </a> */}
        </p>
      );
      break;
    case "KOLVerified":
      text = (
        <p>
          Verified social handle
          {/* Proof:{" "}
          <a
            target="_blank"
            href={data.metadata}
            className="break-all text-link"
          >
            {data.metadata}
          </a> */}
        </p>
      );
      break;
    case "AssignedToKOL":
      text = (
        <p>
          Assigned <FormatTokenAmount token={data.amount} />{" "}
          <span className="text-accent">${data.ticker}</span> to{" "}
          {/* <a
            className="break-all text-link"
            target="_blank"
            href={reverseHandle(data.metadata) || data.metadata}
          > */}
          {data.metadata}
          {/* </a> */}
        </p>
      );
      break;
    case "ClaimedKOL":
      text = (
        <p>
          Claimed <FormatTokenAmount token={data.amount} /> alloted{" "}
          <span className="text-accent">${data.ticker}</span>
        </p>
      );
      break;
    // case "ClaimedReflection":
    //   text = (
    //     <p>
    //       Claimed <FormatEthAmount eth={data.price} />Ξ Reflection from{" "}
    //       <span className="text-accent">${data.ticker}</span>
    //     </p>
    //   );
    //   break;
    default:
      return null;
  }

  return (
    <div className="flex items-center gap-1">
      <Link to={`/people/${data.user_address}`} className="min-w-fit">
        <Image
          className="h-5 w-5 rounded-full"
          src={data.user_profile_image || ""}
          isNsfw={data?.user_image_is_nsfw}
          variant="profile"
          address={data.user_address}
        />
      </Link>
      <Link to={`/coins/${data.tokenAddress}`}>
        <div className="ml-1">
          {/* <p className="flex gap-2">
          <Link to={`/people/${data.user_address}`}>
            @{data.users_username}{" "}
            <span className="text-sm text-subtext">
              {displayAddress(data.user_address)}
            </span>
          </Link>
        </p> */}
          {text}
        </div>
      </Link>
      {/* <span>Sold $0.00001 of $KITTYANN</span> */}
      <Link to={`/coins/${data.tokenAddress}`} className="min-w-fit">
        <Image
          src={data.token_image}
          variant="token"
          isNsfw={data.token_image_is_nsfw}
          className="h-5 w-5"
        />
      </Link>
    </div>
  );
};

export default Activity;
