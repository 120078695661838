import { FormatTokenAmount } from "@/components/FormatAmount";
import displayAddress from "@/Utils/displayAddress";
import { stonkClient } from "@/Utils/stonkClient";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

type Holder = {
  userAddress: `0x${string}`;
  balance: number;
  username: string | null;
  image: string | null;
};

type Props = {
  tokenAddress: `0x${string}`;
  tokenCreated: boolean;
};

const Holders = ({ tokenAddress, tokenCreated }: Props) => {
  const { data } = useQuery({
    queryKey: ["token", tokenAddress, "holders"],
    queryFn: async () => {
      const res = await stonkClient(`token/${tokenAddress}/holders/`);
      return res.data.data as Holder[];
    },
    refetchInterval: import.meta.env.VITE_DISABLE_POOLING ? undefined : 10000,
    enabled: tokenCreated,
  });

  return (
    <div>
      {!tokenCreated && (
        <div className="text-center text-md text-secondary-foreground">
          Coin not created onchain yet. Buy to create and view holder data.
        </div>
      )}
      {data &&
        data.map((holder, i) => (
          <div
            key={holder.userAddress + holder.username}
            className="flex gap-2 text-secondary-foreground"
          >
            <div>{i + 1}. </div>
            <Link to={`/people/${holder.userAddress}`}>
              <div>{holder.username ?? displayAddress(holder.userAddress)}</div>
            </Link>
            <div className="ml-auto">
              <FormatTokenAmount token={holder.balance} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Holders;
