import { stonkClient } from "@/Utils/stonkClient";
import { walletAtom } from "@/state/wallet";
import { UserData } from "@/types/BaseTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useEffect } from "react";
import {
  useAccount,
  useAccountEffect,
  useDisconnect,
  useSignMessage,
} from "wagmi";

type SignInParams = {
  address: `0x${string}`;
  signature: `0x${string}`;
};

const useWalletSignIn = () => {
  const [wallet, setWallet] = useAtom(walletAtom);
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const queryClient = useQueryClient();
  const { isConnected } = useAccount();

  const signIn = useMutation<UserData, unknown, SignInParams>({
    mutationKey: ["signIn"],
    mutationFn: async ({ address, signature }: SignInParams) => {
      const res = await stonkClient.post("signIn/", null, {
        params: {
          user_address: address,
          user_signature: signature,
        },
      });
      if (!res.data.data) throw new Error(res.data.message);
      return res.data.data as UserData;
    },
  });

  useEffect(() => {
    // clear wallet data from local storage if wallet is disconnected due to any reason
    if (!isConnected && wallet) {
      setWallet(null);
    }
  }, [isConnected, wallet, setWallet]);

  useAccountEffect({
    onConnect: (data) => {
      // If wallet was already connected before, and we already have the signature, then return
      if (wallet && wallet.address === data.address) return;

      setWallet(null);

      const postConnectFlow = async () => {
        // Get signature
        const signature = await signMessageAsync({
          message:
            "Sign to verify your user address with Stonk.Social on Base Testnet and Mainnet",
        });

        // Try signin with the signature (creates user if not exists)
        await signIn.mutateAsync({
          address: data.address,
          signature,
        });

        // Invalidate all user query cache
        queryClient.invalidateQueries({ queryKey: ["user"] });

        // Save wallet address and signature in local storage/global state
        setWallet({
          address: data.address,
          signature,
          status: "connected",
        });
      };
      postConnectFlow().catch((e) => {
        console.log("error signing in", e);
        disconnect();
      });
    },
    onDisconnect: () => {
      console.log("disconnected");
      // Clear wallet from local storage/global state
      setWallet(null);
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });
};
export default useWalletSignIn;
