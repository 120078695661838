import { stonkClient } from "@/Utils/stonkClient";
import CopyTooltip from "@/components/CopyTooltip";
import { FormatEthAmount } from "@/components/FormatAmount";
import TradingView from "@/components/TradingView/TradingView";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";
import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import Chat from "./Chat";

import displayAddress from "@/Utils/displayAddress";
import { useEthConversion } from "@/hooks/useEthPrice";
import useWallet from "@/hooks/useWallet";
import { TokenData, TokenSettings } from "@/types/BaseTypes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BuySellTab } from "./BuySellTab";
import Info from "./Info";
import { Replies } from "./ReplyThread";
import SettingsDialog from "./SettingsDialog";
import Trades from "./Trades";

type Props = {
  tokenId: `0x${string}`;
};
const TokenPage = ({ tokenId }: Props) => {
  // const { tokenId = "0x" } = useParams<{ tokenId: `0x${string}` }>();

  const tradingViewRef = useRef<any | null>(null); // eslint-disable-line
  const [tradingViewKey, setTradingViewKey] = useState(0);
  const newToken = useRef(false);
  const wallet = useWallet();

  const { compactUSD } = useEthConversion();
  const tokenData = useQuery({
    queryKey: ["token", tokenId],
    queryFn: async () => {
      const res = await stonkClient(`token/${tokenId}/`);
      return res.data.data as TokenData & TokenSettings;
    },
    refetchInterval: import.meta.env.VITE_DISABLE_POOLING ? undefined : 1000,
  });

  useEffect(() => {
    if (tokenData.data && tokenData.data.soldInBondingCurve === 0) {
      newToken.current = true;
    }
  }, [tokenData.data]);

  const onBuy = () => {
    // Reload trading view on first buy
    if (newToken.current) {
      // This trading view api does not working (according to docs it should work)
      // tradingViewRef.current?.activeChart().resetData();
      // So using this hack to rerender the whole trading view component
      setTradingViewKey((prev) => prev + 1);
    }
  };

  if (tokenData.isLoading) return <div>Loading...</div>;
  if (tokenData.isError) return <div>Error:{tokenData.error.message}</div>;
  if (!tokenData.data) return <div>No data found</div>;
  return (
    <div className="mb-10 flex w-full flex-col gap-2" key={tokenId}>
      {/* Nav bar */}
      {/* <div className="hidden items-center gap-4 sm:flex">
        <Link to="/">
          <img src="/Logo.png" className="h-10 w-10"></img>
        </Link>
        <span
          className="cursor-pointer text-md text-secondary-foreground"
          onClick={openHowItWorks}
        >
          [How it works]
        </span>
        {tokenData.data.soldInBondingCurve >=
          tokenData.data.graduationTokenAmount && (
          <GraduatedDialog tokenAddress={tokenId} />
        )}
        <div className="ml-auto">
          <ConnectButton accountStatus={"address"} />
        </div>
      </div> */}
      {/* Title Section */}
      <div className="flex gap-2">
        {/* <div className="mt-2 cursor-pointer" onClick={() => navigate(-1)}>
          <BackIcon />
        </div> */}
        <h1 className="text-lg">
          {tokenData.data.name}{" "}
          <span className="text-secondary-foreground">
            ${tokenData.data.ticker}{" "}
            {wallet?.address === tokenData.data.creator_address && (
              <SettingsDialog
                tokenId={tokenId}
                settings={{
                  twitter: tokenData.data.twitter,
                  telegram: tokenData.data.telegram,
                  website: tokenData.data.website,
                  chat_access_balance: tokenData.data.chat_access_balance,
                }}
              />
            )}
          </span>
        </h1>
        {/* <CopyTooltip
            textToCopy={tokenData.data.id}
            tooltipText="Copy"
            side="right"
          >
            <p className="cursor-pointer truncate text-sm text-secondary-foreground sm:text-md">
              {tokenData.data.id}
            </p>
          </CopyTooltip> */}
      </div>
      <div className="flex flex-wrap gap-x-4 gap-y-1 text-sm">
        {/* <span className="text-secondary-foreground">{tokenData.data.name}</span>
        <span className="text-secondary-foreground">
          Ticker: {tokenData.data.ticker}
        </span> */}
        <CopyTooltip
          textToCopy={tokenData.data.id}
          tooltipText="Copy"
          side="bottom"
        >
          <p className="cursor-pointer text-secondary-foreground">
            {displayAddress(tokenData.data.id)}
          </p>
        </CopyTooltip>
        <span className="text-accent-green">
          Price: <FormatEthAmount eth={tokenData.data.pricePerToken} />Ξ (
          {compactUSD((tokenData.data.pricePerToken || 0) / 1e18)})
        </span>
        <span className="text-accent-green">
          Mcap: {compactUSD((tokenData.data.marketCap || 0) / 1e18)}
        </span>
        <span className="text-secondary-foreground">
          Created By:{" "}
          <Link
            to={`/people/${tokenData.data.creator_address}`}
            className="text-link"
          >
            @{tokenData.data.creator_username}
          </Link>
        </span>
      </div>
      <div className="h-96 xl:hidden">
        <TradingView
          token={tokenData.data}
          tradingViewRef={tradingViewRef}
          key={tokenId + tradingViewKey}
        />
      </div>
      <div className="flex flex-wrap justify-between gap-4 lg:flex-nowrap">
        {/* Main section (left) */}
        <div className="flex min-w-0 flex-auto flex-col">
          <div className="hidden h-80 gap-4 xl:flex">
            <TradingView
              token={tokenData.data}
              tradingViewRef={tradingViewRef}
              key={tradingViewKey}
            />
          </div>
          <Tabs defaultValue="replies" className="hidden w-full lg:block">
            <TabsList>
              <TabsTrigger value="replies">Replies</TabsTrigger>
              <TabsTrigger className="" value="chat">
                Holders Chat
              </TabsTrigger>
              <TabsTrigger value="trades">Trades</TabsTrigger>
            </TabsList>
            <div className="pt-3">
              <TabsContent value="replies">
                <Replies tokenAddress={tokenId} />
              </TabsContent>
              <TabsContent value="trades">
                <Trades
                  tokenAddress={tokenId}
                  tokenTicker={tokenData.data.ticker}
                />
              </TabsContent>
              <TabsContent value="chat">
                <div className="h-96">
                  <Chat
                    tokenAddress={tokenId}
                    ticker={tokenData.data.ticker}
                    chat_access_balance={tokenData.data.chat_access_balance}
                  />
                </div>
              </TabsContent>
            </div>
          </Tabs>
        </div>
        {/* Right Section */}
        <div className="flex w-full flex-none flex-col gap-3 lg:w-[350px]">
          <div className="flex rounded-lg bg-b1 py-1 lg:hidden">
            <Sheet>
              <SheetTrigger asChild>
                <button className="w-full border-r border-subtext py-1">
                  Replies
                </button>
              </SheetTrigger>
              <SheetContent
                side="bottom"
                className="flex h-[80%] flex-col px-0"
              >
                <SheetTitle className="px-4">Replies</SheetTitle>
                <div className="overflow-auto">
                  <Replies tokenAddress={tokenId} />
                </div>
              </SheetContent>
            </Sheet>
            <Sheet>
              <SheetTrigger asChild>
                <button className="w-full border-r border-subtext py-1">
                  Chat
                </button>
              </SheetTrigger>
              <SheetContent
                side="bottom"
                className="flex h-[80%] flex-col px-4"
              >
                <SheetTitle>Holders Chat</SheetTitle>
                <div className="flex-auto overflow-y-auto">
                  <Chat
                    tokenAddress={tokenId}
                    ticker={tokenData.data.ticker}
                    chat_access_balance={tokenData.data.chat_access_balance}
                  />
                </div>
              </SheetContent>
            </Sheet>
            <Sheet>
              <SheetTrigger asChild>
                <button className="w-full py-1">Trades</button>
              </SheetTrigger>
              <SheetContent
                side="bottom"
                className="flex h-[80%] flex-col px-4"
              >
                <SheetTitle>Trades</SheetTitle>
                <div className="overflow-y-auto">
                  <Trades
                    tokenAddress={tokenId}
                    tokenTicker={tokenData.data.ticker}
                  />
                </div>
              </SheetContent>
            </Sheet>
          </div>
          {/* <div className="ml-auto"></div> */}
          <BuySellTab tokenData={tokenData.data} onBuy={onBuy} />
          <p className="text-md font-semibold text-secondary-foreground">
            On reaching graduation, the last buyer will get 0.05Ξ (
            {compactUSD(0.05)}), and the creator will get 0.05Ξ (
            {compactUSD(0.05)}).
          </p>
          {/* <ClaimRewards tokenAddress={tokenId} /> */}

          <Info tokenData={tokenData.data} />
        </div>
      </div>
    </div>
  );
};

export default TokenPage;
