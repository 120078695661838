import { FormatTokenAmount } from "@/components/FormatAmount";
import { Input } from "@/components/ui/input";
import useWallet from "@/hooks/useWallet";
import { ChatMessage } from "@/types/BaseTypes";
import { stonkClient } from "@/Utils/stonkClient";
import { LockClosedIcon, PaperPlaneIcon } from "@radix-ui/react-icons";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useBalance } from "wagmi";

type props = {
  tokenAddress: `0x${string}`;
  ticker: string;
  chat_access_balance: number;
};
const Chat = ({ tokenAddress, ticker, chat_access_balance }: props) => {
  const endOfChatRef = useRef<HTMLDivElement>(null);
  const [chats, setChats] = useState<ChatMessage[]>([]);

  const [message, setMessage] = useState("");

  const wallet = useWallet();

  const { data: tokenBalance } = useBalance({
    address: wallet?.address,
    token: tokenAddress,
  });

  const allowAccess =
    !!tokenBalance && tokenBalance.value >= chat_access_balance;

  useEffect(() => {
    if (!allowAccess) return;
    const ws = new WebSocket(
      `${import.meta.env.VITE_API_WS_URL}/chat/${tokenAddress}/?user_address=${wallet?.address}&user_signature=${wallet?.signature}`
    );

    ws.onopen = () => {
      console.log("WebSocket connected");
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "history") {
        setChats(data.data as ChatMessage[]);
      } else if (data.type === "new_message") {
        setChats((prevChats) => [...prevChats, data.data as ChatMessage]);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      ws.close();
    };
  }, [allowAccess]);

  const sendMessage = async () => {
    if (!wallet || !allowAccess || !message.trim()) return;
    const messageData = {
      user_address: wallet.address,
      message: message.trim(),
      user_signature: wallet.signature,
    };
    await stonkClient.post(`/chat/${tokenAddress}/`, null, {
      params: messageData,
    });
    setMessage("");
  };

  useEffect(() => {
    endOfChatRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [chats]);

  return (
    <div className="relative flex h-full flex-col bg-b1">
      <div className="relative flex h-full min-h-0 flex-auto flex-col gap-2 overflow-y-auto p-2 text-sm">
        {chats.map((chat, index) => (
          <div key={index} className="flex items-center gap-2">
            {/* <div className="rounded-full bg-accent p-2">Sender</div> */}
            <div className="flex flex-col">
              <Link to={`/people/${chat.user?.user_address}`}>
                <span className="text-link">@{chat.user?.username}</span>
              </Link>
              <span>{chat.message}</span>
            </div>
          </div>
        ))}

        <div className="translate-y-20" ref={endOfChatRef}></div>
      </div>
      {!allowAccess && (
        <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center text-center text-md text-secondary-foreground">
          <LockClosedIcon className="h-8 w-8" />
          <span>
            <FormatTokenAmount token={chat_access_balance} /> ${ticker} needed
            to access the chat
          </span>
        </div>
      )}
      <div className="flex-none border-t-2 border-b3 p-2">
        <form
          className="flex items-center gap-2 text-md"
          onSubmit={(e) => {
            e.preventDefault();
            console.log("sending message", message);
            sendMessage();
          }}
        >
          <Input
            placeholder="Type a message..."
            autoComplete="off"
            disabled={!allowAccess}
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button type="submit" className="w-fit" disabled={!allowAccess}>
            <PaperPlaneIcon className="h-4 w-4 cursor-pointer" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Chat;
