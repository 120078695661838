// TypeScript Code for Token Creation Form

export interface BondingCurveRates {
  HODL: number;
  MOON: number;
  MARS: number;
}

export const bondingCurveGrowthRate: BondingCurveRates = {
  HODL: 25,
  MOON: 75,
  MARS: 225,
};

export const curveTypeToName: Record<number, keyof BondingCurveRates> = {
  0: "HODL",
  1: "MOON",
  2: "MARS",
};

const PRECISION = 1e30;
const BONDING_CURVE_CONSTANT = 3 * PRECISION * 10 ** 2;

const totalSupply = 1e9; // 1B tokens
const ethPrice = 3000; // Example ETH price in dollars
const oneToken = 1e8;

/**
 * Calculates the price given current supply, amount, and bonding curve growth rate.
 *
 * @param {number} s - The current supply.
 * @param {number} a - The amount.
 * @param {number} g - The bonding curve growth rate.
 * @return {number} The calculated price in ETH with 1e18 factor.
 */
export function getPrice(s: number, a: number, g: number): number {
  const term1 = g * s * s * a;
  const term2 = g * s * a * a;
  const term3 = (g * a * a * a) / 3;
  const term4 = s * a;
  const term5 = (a * a) / 2;
  const term6 = a / 6;

  const summation = term1 + term2 + term3 - term4 - term5 + term6;
  return summation / BONDING_CURVE_CONSTANT;
}
export function getPriceBig(s: bigint, a: bigint, g: bigint): bigint {
  const term1 = g * s * s * a;
  const term2 = g * s * a * a;
  const term3 = (g * a * a * a) / 3n;
  const term4 = s * a;
  const term5 = (a * a) / 2n;
  const term6 = a / 6n;

  const summation = term1 + term2 + term3 - term4 - term5 + term6;
  return summation / BigInt(BONDING_CURVE_CONSTANT);
}

export function getTokenCurrentPrice(
  supply: number,
  curveType: 0 | 1 | 2
): number {
  const growthRate = bondingCurveGrowthRate[curveTypeToName[curveType]];
  return getPrice(supply, 1, growthRate);
}

export function getGraduationMarketCap(
  graduationTokenAmount: number,
  curveType: 0 | 1 | 2
): number {
  const growthRate = bondingCurveGrowthRate[curveTypeToName[curveType]];
  return getPrice(graduationTokenAmount, oneToken, growthRate) * totalSupply;
}

function calculateTokensRequiredForLiquidity(
  graduationTokenAmount: number,
  curveType: keyof BondingCurveRates
): number {
  const bondingCurveETH = getPrice(
    0,
    graduationTokenAmount,
    bondingCurveGrowthRate[curveType]
  );
  const currentPrice = getPrice(
    graduationTokenAmount,
    1,
    bondingCurveGrowthRate[curveType]
  );
  const tokensRequiredInLiquidity = (bondingCurveETH * 1) / currentPrice;
  return tokensRequiredInLiquidity + 1;
}

function calculateTokenData(
  graduationTokenAmount: number,
  curveType: keyof BondingCurveRates
): string {
  const totalSupply = 1e9; // 1B tokens
  const ethPrice = 3000; // Example ETH price in dollars
  const oneToken = 1e8;

  const X = getPrice(
    0,
    graduationTokenAmount * oneToken,
    bondingCurveGrowthRate[curveType]
  );
  const Y = (graduationTokenAmount / totalSupply) * 100;
  const K =
    getPrice(
      graduationTokenAmount * oneToken,
      oneToken,
      bondingCurveGrowthRate[curveType]
    ) / 1e18;
  const liquidity = calculateTokensRequiredForLiquidity(
    graduationTokenAmount,
    curveType
  );
  const reservePercentage =
    ((totalSupply - graduationTokenAmount - liquidity) / totalSupply) * 100;
  const liquidityPercentage = (liquidity / totalSupply) * 100;

  const graduationMC = K * totalSupply;

  return `It will take ${X} ETH (equivalent to $${
    X * ethPrice
  }) to sell ${Y}% supply, making the graduation market cap ${graduationMC} ETH (${
    graduationMC * ethPrice
  } USD) and listing price ${K} ETH (equivalent to $${K * ethPrice}). 
    
    K: ${K} ETH
    K: ${K * ethPrice} USD  
    Reserve: ${reservePercentage}%
    Liquidity: ${liquidity} tokens (${liquidityPercentage}%)
    graduation MC: ${graduationMC} ETH
    graduation MC: ${graduationMC * ethPrice} USD
    graduationTokenAmount: ${graduationTokenAmount / 1e6} //why it is /1e6?
    `;
}
export const getTokenomics = (
  graduationTokenAmount: number,
  curveType: keyof BondingCurveRates,
  buyAmount?: number
) => {
  const totalSupply = 1e9; // 1B tokens
  const ethPrice = 3000; // Example ETH price in dollars
  const oneToken = 1e8;

  const X = getPrice(
    0,
    graduationTokenAmount * oneToken,
    bondingCurveGrowthRate[curveType]
  );
  const Y = (graduationTokenAmount / totalSupply) * 100;
  const K =
    getPrice(
      graduationTokenAmount * oneToken,
      oneToken,
      bondingCurveGrowthRate[curveType]
    ) / 1e18;
  const liquidity = calculateTokensRequiredForLiquidity(
    graduationTokenAmount,
    curveType
  );
  const reservePercentage =
    ((totalSupply - graduationTokenAmount - liquidity) / totalSupply) * 100;
  const liquidityPercentage = (liquidity / totalSupply) * 100;

  const graduationMC = K * totalSupply;

  const buyAmountPriceWei = buyAmount
    ? getPrice(0, buyAmount * oneToken, bondingCurveGrowthRate[curveType])
    : 0;

  return {
    totalCost: X / 1e18,
    graduationSupplyPercentage: Y,
    graduationMC,
    listingPrice: K,
    reservePercentage,
    liquidity,
    liquidityPercentage,
    creationFee: 0.0002,
    buyAmountPriceWei: buyAmountPriceWei * 1.05, // Price + fees
  };
};

export const getInitialBuyPrice = (curveType: number, buyAmount: number) => {
  console.log({ curveType, buyAmount });
  return Math.ceil(
    getPrice(0, buyAmount, bondingCurveGrowthRate[curveTypeToName[curveType]]) *
      1.05 +
      1
  );
};

// Example Usage
// const graduationAmount = 500e6; // 500M tokens
// const curveType: keyof BondingCurveRates = "MOON";
