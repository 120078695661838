type SocialInput = {
  url: string;
  address: string;
};

type SocialParsed = {
  platform: "Twitter" | "Instagram" | "Youtube";
  url: string;
  address: string;
};

export const parseSocials = (socials: SocialInput[]) => {
  if (!socials) return [];
  const parsedSocials: SocialParsed[] = [];
  socials.forEach((social) => {
    if (!social.url) return;
    if (social.url.includes("twitter.com") || social.url.includes("x.com")) {
      parsedSocials.push({
        platform: "Twitter",
        url: social.url,
        address: social.address,
      });
    } else if (social.url.includes("instagram.com")) {
      parsedSocials.push({
        platform: "Instagram",
        url: social.url,
        address: social.address,
      });
    } else if (social.url.includes("youtube.com")) {
      parsedSocials.push({
        platform: "Youtube",
        url: social.url,
        address: social.address,
      });
    }
  });
  return parsedSocials;
};

export const generateHandle = (url: string) => {
  const instagramRegex = /^https:\/\/www\.instagram\.com\/([^/]+)\/?$/;
  const youtubeRegex = /^https:\/\/www\.youtube\.com\/@([^/]+)\/?$/;
  const xRegex = /^https:\/\/x\.com\/([^/]+)\/?$/;

  if (instagramRegex.test(url)) {
    return `instagram.com/${url.match(instagramRegex)?.[1]}`;
  } else if (youtubeRegex.test(url)) {
    return `youtube.com/${url.match(youtubeRegex)?.[1]}`;
  } else if (xRegex.test(url)) {
    return `x.com/${url.match(xRegex)?.[1]}`;
  } else {
    return null;
  }
};

export const reverseHandle = (handle: string) => {
  const instagramHandleRegex = /^instagram\.com\/([^/]+)$/;
  const youtubeHandleRegex = /^youtube\.com\/([^/]+)$/;
  const xHandleRegex = /^x\.com\/([^/]+)$/;

  if (instagramHandleRegex.test(handle)) {
    return `https://www.instagram.com/${
      handle.match(instagramHandleRegex)?.[1]
    }/`;
  } else if (youtubeHandleRegex.test(handle)) {
    return `https://www.youtube.com/@${handle.match(youtubeHandleRegex)?.[1]}`;
  } else if (xHandleRegex.test(handle)) {
    return `https://x.com/${handle.match(xHandleRegex)?.[1]}`;
  } else {
    return null;
  }
};
