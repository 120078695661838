import * as React from "react";

function Back(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.8775 7.83345L8.08 12.0334L6.90167 13.2168L0.6875 7.00011L6.90167 0.783447L8.08 1.96678L3.8775 6.16678H15.1992V7.83345H3.8775Z"
        fill="white"
      />
    </svg>
  );
}

const MemoBack = React.memo(Back);
export default MemoBack;
