import { howItWorksOpenAtom } from "@/state/howItWorks";
import { useAtom } from "jotai";

const useHowItWorks = () => {
  const [howItWorksOpen, setHowItWorksOpen] = useAtom(howItWorksOpenAtom);

  const openHowItWorks = () => setHowItWorksOpen(true);

  return { howItWorksOpen, openHowItWorks };
};

export default useHowItWorks;
