import { stonkClient } from "@/Utils/stonkClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useWallet from "./useWallet";

type FollowParams = {
  followee_address: `0x${string}`;
  follow: boolean;
  user_address: `0x${string}`;
  user_signature: `0x${string}`;
};

const useFollow = () => {
  const queryClient = useQueryClient();
  const wallet = useWallet();

  const mutateFollow = useMutation({
    mutationKey: ["user", ""],
    mutationFn: async (data: FollowParams) => {
      const res = await stonkClient.post(`/follow/`, null, { params: data });
      return res.data.data;
    },
  });

  return async ({
    followee_address,
    follow,
  }: {
    followee_address: `0x${string}`;
    follow: boolean;
  }) => {
    if (!wallet) return;
    await mutateFollow.mutateAsync({
      followee_address,
      follow,
      user_address: wallet.address,
      user_signature: wallet.signature,
    });
    queryClient.invalidateQueries({
      queryKey: ["user", followee_address, "profile"],
    });
  };
};

export default useFollow;
