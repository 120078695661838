import { Theme } from "@radix-ui/themes";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { WagmiProvider } from "wagmi";

import { Toaster } from "@/components/ui/toaster";
import usePreferences from "@/hooks/usePreferences";
import { howItWorksOpenAtom } from "@/state/howItWorks";
import { config, queryClient } from "@/Utils/configs";
import { darkTheme } from "@rainbow-me/rainbowkit";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAtom } from "jotai";
import { PostHogProvider } from "posthog-js/react";
import React, { useEffect } from "react";
import HowItWorks from "./HowItWorks";

const options = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
};

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const [howItWorksOpen, setHowItWorksOpen] = useAtom(howItWorksOpenAtom);
  const { preferences, updatePreferences } = usePreferences();

  useEffect(() => {
    if (!!preferences && !preferences.howItWorksSeen) {
      setHowItWorksOpen(true);
      updatePreferences({ howItWorksSeen: true });
    }
  }, [preferences, updatePreferences]);

  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_KEY}
      options={options}
    >
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            theme={darkTheme({
              accentColor: "hsl(var(--accent))",
              accentColorForeground: "hsl(var(--accent-foreground))",
            })}
          >
            <Theme accentColor="amber">
              {children} <Toaster />
              <HowItWorks open={howItWorksOpen} setOpen={setHowItWorksOpen} />
            </Theme>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="bottom-left"
            />
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </PostHogProvider>
  );
};

export { Wrapper };
