import axios from "axios";
import { stonkClient } from "./stonkClient";
interface PresignedData {
  url: string;
  fields: { [key: string]: string };
}

async function getPresignedUrl(fileType: string): Promise<PresignedData> {
  const response = await stonkClient.get(
    `token/image/upload?file_type=${fileType}`
  );
  return response.data.data as PresignedData; // Type assertion for clarity
}

async function uploadFileToS3(
  presignedData: PresignedData,
  imageFile: File
): Promise<void> {
  const formData = new FormData();
  Object.keys(presignedData.fields).forEach((key) => {
    formData.append(key, presignedData.fields[key]);
  });
  formData.append("file", imageFile);

  const response = await axios.post(presignedData.url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function uploadImageFromForm(
  imageFile: File | string
): Promise<string> {
  try {
    if (typeof imageFile === "string") {
      imageFile = imageFile;
      const response = await axios.get(imageFile, {
        responseType: "blob",
      });
      const blob = response.data;
      const fileType = blob.type.split("/").pop() || ""; // Get the file extension from the blob type
      const file = new File([blob], `image.${fileType}`);
      imageFile = file;
    }
    const fileType = imageFile.name.split(".").pop() || ""; // Handle potential missing extension
    console.log(`imageFile: `, imageFile, imageFile.toString());

    const presignedData = await getPresignedUrl(fileType);
    await uploadFileToS3(presignedData, imageFile);
    return `${presignedData.url}${presignedData.fields.key}`;
  } catch (error) {
    console.error("Error: {error}"); // Use f-string for formatted error message
    throw error; // Re-throw for proper error handling
  }
}
