import { QueryFunction, useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

type Props<TData extends unknown[]> = {
  queryKey: string[];
  queryFn: QueryFunction<TData, string[], number>;
  enabled?: boolean;
  refetchInterval?: number;
};
const useInfiniteScroll = <TData extends unknown[]>({
  queryKey,
  queryFn,
  enabled = true,
  refetchInterval,
}: Props<TData>) => {
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey,
      queryFn,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        // 20 is the default page size
        if (lastPage?.length < 20) {
          return undefined;
        }
        return lastPageParam + 1;
      },
      enabled: !!enabled,
      refetchInterval,
    });

  useEffect(() => {
    if (!hasNextPage) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
        }
      },
      {
        threshold: 1.0,
      }
    );

    const loadMoreDivCurrent = loadMoreRef.current;
    if (loadMoreDivCurrent) {
      observer.observe(loadMoreDivCurrent);
    }

    return () => {
      if (loadMoreDivCurrent) {
        observer.unobserve(loadMoreDivCurrent);
      }
    };
  }, [hasNextPage, fetchNextPage]);

  return { data, loadMoreRef, isLoading, isFetchingNextPage };
};

export default useInfiniteScroll;
