import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
import { useEffect, useState } from "react";

const getMinimalFormat = (startTime: Date) => {
  const now = new Date();
  const seconds = differenceInSeconds(now, startTime);
  const minutes = differenceInMinutes(now, startTime);
  const hours = differenceInHours(now, startTime);
  const days = differenceInDays(now, startTime);

  if (seconds < 60) {
    return `${seconds}s`;
  } else if (minutes < 60) {
    return `${minutes}m`;
  } else if (hours < 24) {
    return `${hours}h`;
  } else {
    return `${days}d`;
  }
};

type Props = {
  eventTime: Date;
};

const TimeElapsed = ({ eventTime }: Props) => {
  const [timeElapsed, setTimeElapsed] = useState("");

  useEffect(() => {
    const updateTimeElapsed = () => {
      setTimeElapsed(getMinimalFormat(new Date(eventTime)));
    };

    updateTimeElapsed();
    const intervalId = setInterval(updateTimeElapsed, 10000); // Update every 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [eventTime]);

  return <span>{timeElapsed}</span>;
};

export default TimeElapsed;
