import HowItWorksIcon from "@/assets/HowItWorksIcon";
import useHowItWorks from "@/hooks/useHowItWorks";
import { Link } from "react-router-dom";
import CustomConnectButton from "./ConnectButton";
import LiveActivity from "./LiveActivity";
import { Button } from "./ui/button";

const TopNav = () => {
  const { openHowItWorks } = useHowItWorks();

  return (
    <div className="mr-4">
      <div className="relative flex items-center gap-2">
        {/* <Sheet>
        <SheetTrigger>
          <div>
            <HamburgerMenuIcon className="h-6 w-6" />
          </div>
        </SheetTrigger>
        <SheetContent side="left" className="py-0">
          <SheetTitle className="hidden">Sidebar menu</SheetTitle>
          <SheetDescription className="hidden">
            Sidebar navigation menu
          </SheetDescription>
          <Sidebar withSheetClose />
        </SheetContent>
      </Sheet> */}
        <Link to="/" className="min-w-fit">
          <img src="/Logo.png" alt="Logo" className="h-8 w-8 rounded-full" />
        </Link>
        <div className="flex cursor-pointer gap-1" onClick={openHowItWorks}>
          <HowItWorksIcon height={20} width={20} />
          <span className="hidden text-nowrap text-md lg:inline">
            How it works
          </span>
        </div>
        <div className="hidden min-w-0 flex-auto sm:block">
          <LiveActivity />
        </div>
        {/* <div
        className="ml-auto flex w-fit flex-none cursor-pointer items-center gap-1"
        onClick={openAccountModal}
      >
        <CustomConnectButton />
        <Image
          src={"/MemeHolder.png"}
          address={wallet?.address || "0x"}
          isNsfw={false}
          variant="profile"
          className="h-6 w-6 rounded-full"
        />
        <p className="text-md">Anshit01</p>
        <ChevronDownIcon className="h-2 w-2" />
        </div> */}
        <Link to="/ideas/create" className="ml-auto">
          <Button
            variant={"gradient"}
            size="md"
            className="flex-none px-4 text-md font-bold hover:brightness-125"
          >
            Create a coin
          </Button>
        </Link>
        <div className="flex w-fit flex-none gap-2">
          {/* <CoinbaseCreateWallet /> */}
          <CustomConnectButton variant="minimal" />
        </div>
      </div>
      <div className="mt-2 w-full min-w-0 sm:hidden">
        <LiveActivity />
      </div>
    </div>
  );
};

export default TopNav;
