import usePreferences from "@/hooks/usePreferences";
import { cn } from "@/lib/utils";

type Props = {
  className?: string;
  src?: string;
  alt?: string;
  isNsfw: boolean;
} & (
  | {
      variant: "profile";
      address: string;
    }
  | {
      variant: "token";
    }
  | {
      variant: "comment";
    }
);
const ALTS = {
  profile: "Profile image",
  token: "Coin image",
  comment: "Comment image",
};

const Image = (props: Props) => {
  const { preferences } = usePreferences();

  if (props.variant === "comment" && !props.src) return <></>;

  return (
    <div
      className={cn("h-fit w-fit min-w-fit overflow-hidden", {
        "rounded-full": props.variant === "profile",
        "rounded-2xl": props.variant === "token",
        "rounded-sm": props.variant === "comment",
      })}
    >
      <img
        src={props.src || ""}
        alt={props.alt || ALTS[props.variant]}
        className={cn(
          "",
          {
            "blur-md filter": props.isNsfw && preferences.hideNsfw,
          },
          props.className
        )}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          // if (props.variant === "profile") {
          //   currentTarget.src = getDefaultImageForAddress(props.address);
          //   return;
          // }
          // currentTarget.src = "https://cdn.nurturelabs.co/default.gif";
        }}
      />
    </div>
  );
};

export default Image;

const DEFAULT_PROFILE_IMAGES = [
  "/parrot/parrot.gif",
  "/parrot/fastparrot.gif",
  "/parrot/slowparrot.gif",
];

const getDefaultImageForAddress = (address: `0x${string}` = "0x0") => {
  const lastChar = address[address.length - 1];
  const num = parseInt(lastChar, 16) || 0; // hex char to int, 0 if invalid char
  return DEFAULT_PROFILE_IMAGES[num % DEFAULT_PROFILE_IMAGES.length];
};
