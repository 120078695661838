import * as React from "react";

function InfoIconComp({
  width = 10,
  height = 10,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} {...props}>
      <path d="M4.5 7.5h1v-3h-1v3zm.5-4c.142 0 .26-.048.356-.144A.484.484 0 005.5 3a.484.484 0 00-.144-.356A.484.484 0 005 2.5a.484.484 0 00-.356.144A.484.484 0 004.5 3c0 .142.048.26.144.356A.484.484 0 005 3.5zM5 10a4.869 4.869 0 01-1.95-.394 5.05 5.05 0 01-1.587-1.069A5.05 5.05 0 01.392 6.95 4.869 4.869 0 010 5c0-.692.131-1.342.394-1.95a5.05 5.05 0 011.068-1.587c.45-.45.98-.807 1.588-1.07A4.869 4.869 0 015 0c.692 0 1.342.131 1.95.394a5.05 5.05 0 011.587 1.068c.45.45.807.98 1.07 1.588C9.868 3.658 10 4.308 10 5s-.131 1.342-.394 1.95a5.05 5.05 0 01-1.069 1.587c-.45.45-.979.807-1.587 1.07A4.869 4.869 0 015 10zm0-1c1.117 0 2.063-.387 2.838-1.162C8.613 7.062 9 6.117 9 5s-.387-2.063-1.162-2.837C7.062 1.388 6.117 1 5 1s-2.063.387-2.837 1.163C1.388 2.938 1 3.883 1 5c0 1.117.387 2.063 1.163 2.838C2.938 8.613 3.883 9 5 9z" />
    </svg>
  );
}

const InfoIcon = React.memo(InfoIconComp);
export default InfoIcon;
