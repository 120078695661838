import { AiOutlineLoading } from "react-icons/ai";

const Loader = () => {
  return (
    <div>
      <AiOutlineLoading className="animate-spin" size={20} />
    </div>
  );
};

export default Loader;
