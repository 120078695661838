import { ProfileCardDetailed } from "@/components/ProfileCard";
import Filters from "@/components/RightSection/Filters";
import Search from "@/components/RightSection/Search";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePreferences from "@/hooks/usePreferences";
import useWallet from "@/hooks/useWallet";
import { cn } from "@/lib/utils";
import { UserData } from "@/types/BaseTypes";
import { stonkClient } from "@/Utils/stonkClient";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  kingOfStonk?: UserData[];
  isMobile: boolean;
};
const PeopleTab = ({ kingOfStonk, isMobile }: Props) => {
  const { preferences } = usePreferences();
  const wallet = useWallet();
  const location = useLocation();
  const navigate = useNavigate();

  const [, , selectedCoinAddress] = location.pathname.split("/");

  const { data, loadMoreRef, isLoading, isFetchingNextPage } =
    useInfiniteScroll({
      queryKey: ["user", JSON.stringify(preferences.peopleFilters)],
      queryFn: async ({ pageParam }) => {
        const filters = [];
        if (preferences.peopleFilters.onlyFollowing) filters.push("followed");
        const res = await stonkClient(`user/`, {
          params: {
            user_address: wallet?.address || undefined,
            sort_by: preferences.peopleFilters.sortBy,
            order_by: preferences.peopleFilters.order,
            filter_by: filters,
            page: pageParam,
          },
        });
        return res.data.data as UserData[];
      },
    });

  useEffect(() => {
    if (selectedCoinAddress) return;
    if (!isMobile) {
      if (kingOfStonk?.length) {
        navigate(`/people/${kingOfStonk[0].user_address}`);
      } else if (data?.pages[0]?.length) {
        navigate(`/people/${data.pages[0][0].user_address}`);
      }
    }
  }, [kingOfStonk, data]);

  return (
    <div className="flex flex-col gap-2">
      <Search type="creator" />
      <Filters currentTab="people" />
      {/* {false && !!kingOfStonk && (
        <div
          className={cn(
            "rounded-md border border-[#FF4C0060] shadow-none hover:shadow-[0_0_10px_5px_#FF4C0060]"
            // {
            //   "shadow-[0_0_10px_5px_#FF4C0060]":
            //     selectedCoinAddress === ultraStonk.id,
            // }
          )}
        >
          <div className="-mb-4 flex">
            <img
              src={ultrastonkTitle}
              alt="ultra stonk"
              className="w-2/3 max-w-[250px]"
            />
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger>
                  <Info className="ml-1 fill-accent" />
                </TooltipTrigger>
                <TooltipContent side="top">
                  <p className="max-w-64">
                    Latest coin to reach Market cap of 5 ETH
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          {kingOfStonk?.map((user) => (
            <ProfileCardDetailed
              key={user.user_address}
              username={user.username}
              address={user.user_address}
              avatarUrl={user.profile_image}
              bio={user.bio}
              is_nsfw={user.is_nsfw}
              coinsCreated={user.tokens_created}
              coinsHeld={user.token_held}
              followers={user.followers}
              following={user.following}
              score={user.score}
            />
          ))}
        </div>
      )} */}
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          {!!data &&
            data.pages.map((group, i) => (
              <React.Fragment key={i}>
                {group.map((user) =>
                  true ||
                  !kingOfStonk?.some(
                    (king) => user.user_address === king.user_address
                  ) ? (
                    <div
                      key={user.user_address}
                      className={cn(
                        "rounded-md border border-transparent hover:border-gray-500",
                        {
                          "border-gray-500":
                            selectedCoinAddress === user.user_address,
                        }
                      )}
                    >
                      <ProfileCardDetailed
                        key={user.user_address}
                        username={user.username}
                        address={user.user_address}
                        avatarUrl={user.profile_image}
                        bio={user.bio}
                        is_nsfw={user.is_nsfw}
                        coinsCreated={user.tokens_created}
                        coinsHeld={user.token_held}
                        followers={user.followers}
                        following={user.following}
                        score={user.score}
                      />
                    </div>
                  ) : null
                )}
              </React.Fragment>
            ))}
        </div>
        <div ref={loadMoreRef} id="load-more" style={{ height: 1 }} />
        {isFetchingNextPage && <p>Loading more...</p>}
        {isLoading && !isFetchingNextPage && <p>Loading...</p>}
        {!!data && (data.pages.length === 0 || data?.pages[0].length === 0) && (
          <p>Nothing here yet</p>
        )}
      </div>
    </div>
  );
};

export default PeopleTab;
