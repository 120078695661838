import { stonkClient } from "@/Utils/stonkClient";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import useWallet from "@/hooks/useWallet";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

type SettingsDialogProps = {
  tokenId: `0x${string}`;
  settings: {
    twitter?: string;
    telegram?: string;
    website?: string;
    chat_access_balance: number;
  };
};

const validationSchema: Yup.ObjectSchema<SettingsDialogProps["settings"]> =
  Yup.object().shape({
    twitter: Yup.string().url("Invalid URL"),
    telegram: Yup.string().url("Invalid URL"),
    website: Yup.string().url("Invalid URL"),
    chat_access_balance: Yup.number()
      .min(0, "Must be a positive number")
      .max(1e17, "Cannot be greater than total supply")
      .required("Required"),
  });

const SettingsDialog = ({ tokenId, settings }: SettingsDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wallet = useWallet();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { mutate: updateSettings } = useMutation({
    mutationFn: (values: typeof settings) => {
      return stonkClient.post(`/token/${tokenId}/settings/`, null, {
        params: {
          user_address: wallet?.address,
          user_signature: wallet?.signature,
          ...values,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["token", tokenId] });
      toast({
        title: "Settings updated successfully",
      });
    },
    onError: () => {
      toast({
        title: "Error updating settings",
      });
    },
  });
  const handleSave = async (values: typeof settings) => {
    updateSettings(values);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="md">
          <Pencil1Icon className="mr-1 h-4 w-4" /> Edit
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Settings</DialogTitle>
          <DialogDescription>
            Make changes to your token settings here. Click save when you're
            done.
          </DialogDescription>
        </DialogHeader>
        <Formik
          initialValues={settings}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <Form className="flex flex-col gap-4 py-4">
              {(["twitter", "telegram", "website"] as const).map((field) => (
                <div key={field}>
                  <Label htmlFor={field} className="capitalize">
                    {field}
                  </Label>
                  <Input
                    id={field}
                    name={field}
                    className="flex-grow"
                    value={values[field]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors[field] && touched[field] && (
                    <div className="text-sm text-red">{errors[field]}</div>
                  )}
                </div>
              ))}
              <div>
                <Label htmlFor="chat_access_balance">Chat Access Balance</Label>
                <Input
                  id="chat_access_balance"
                  name="chat_access_balance"
                  type="number"
                  className="flex-grow"
                  value={values.chat_access_balance / 1e8}
                  onChange={(e) =>
                    setFieldValue(
                      "chat_access_balance",
                      Number(e.target.value) * 1e8
                    )
                  }
                  onBlur={handleBlur}
                />
                {errors.chat_access_balance && touched.chat_access_balance && (
                  <div className="text-sm text-red">
                    {errors.chat_access_balance}
                  </div>
                )}
              </div>
              <DialogFooter className="flex justify-end gap-4">
                <Button variant="outline" onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit">Save changes</Button>
              </DialogFooter>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;
