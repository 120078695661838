import { ThemeProvider } from "@/components/ThemeProvider";
import { Wrapper } from "@/components/Wrapper";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui">
      {/* <Button>Hello</Button> */}
      <Wrapper>
        <AppRoutes />
      </Wrapper>
      {/* <div className="w-full h-5 bg-[red]"></div> */}
    </ThemeProvider>
  );
}

export default App;
