export type SocialProps = {
  site: "twitter" | "instagram";
  link: string;
};

const ImagePath = {
  twitter: "/icons/twitter.png",
  instagram: "/icons/instagram.png",
};

const SocialLink = ({ site, link }: SocialProps) => {
  return (
    <div className="inline-block">
      <a href={link} target="_blank">
        <img src={ImagePath[site]} className="h-4 w-4" alt={site} />
      </a>
    </div>
  );
};

export default SocialLink;
