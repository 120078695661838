import { preferencesAtom } from "@/state/preferences";
import { useAtom } from "jotai";
import { useCallback } from "react";

const usePreferences = () => {
  const [preferences, setPreferences] = useAtom(preferencesAtom);

  const updatePreferences = useCallback(
    (newPreferences: Partial<typeof preferences>) => {
      setPreferences((prev) => ({ ...prev, ...newPreferences }));
    },
    [setPreferences]
  );
  return { preferences, updatePreferences };
};

export default usePreferences;
