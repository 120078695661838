import { Button } from "@/components/ui/button";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";
import useWallet from "@/hooks/useWallet";
import { ExplorerData } from "@/types/BaseTypes";
import { stonkClient } from "@/Utils/stonkClient";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CreateAToken from "../CreateAToken";
import Profile from "../Profile";
import TokenPage from "../Token";
import CoinsTab from "./CoinsTab";
import IdeasTab from "./IdeasTab";
import PeopleTab from "./PeopleTab";

const index = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const wallet = useWallet();

  const [, tab, id] = location.pathname.split("/");
  console.log(tab, id);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640); // 640px is the 'sm' breakpoint in Tailwind
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const { data: ExplorerData } = useQuery({
    queryKey: ["explorer"],
    queryFn: async () => {
      const res = await stonkClient.get("explorer/", {
        params: { viewer_address: wallet?.address },
      });
      return res.data.data as ExplorerData;
    },
    refetchInterval: import.meta.env.VITE_DISABLE_POOLING ? undefined : 1000,
  });

  return (
    <div className="flex h-full">
      <Tabs
        value={tab || "coins"}
        className="scroll-hidden mr-4 h-full w-1/3 flex-auto overflow-y-scroll sm:mr-0 sm:flex-none lg:w-1/4 2xl:w-1/5"
      >
        <div className="sticky top-0 bg-black/80">
          <TabsList className="flex justify-center">
            <TabsTrigger value="coins" asChild>
              <Link to="/coins">Coins</Link>
            </TabsTrigger>
            <TabsTrigger value="people" asChild>
              <Link to="/people">People</Link>
            </TabsTrigger>
            <TabsTrigger value="ideas" asChild>
              <Link to="/ideas">Ideas</Link>
            </TabsTrigger>
          </TabsList>
        </div>
        <div className="mb-8 h-fit min-h-0">
          <TabsContent value="coins">
            <CoinsTab
              ultraStonk={ExplorerData?.ultra_stonk}
              isMobile={isMobile}
            />
          </TabsContent>
          <TabsContent value="people">
            <PeopleTab
              kingOfStonk={ExplorerData?.king_of_stonks}
              isMobile={isMobile}
            />
          </TabsContent>
          <TabsContent value="ideas">
            <IdeasTab isMobile={isMobile} />
          </TabsContent>
        </div>
      </Tabs>
      {isMobile ? (
        <Sheet
          open={!!id}
          onOpenChange={(open) => !open && navigate(`/${tab}`)}
        >
          <SheetContent
            side="bottom"
            className="h-[100dvh] w-screen overflow-y-scroll"
          >
            {/* <div className="h-fit min-h-full flex-auto justify-center overflow-y-scroll px-4"> */}
            {tab === "coins" && <TokenPage tokenId={id as `0x${string}`} />}
            {tab === "people" && <Profile userAddress={id as `0x${string}`} />}
            {tab === "ideas" && <CreateAToken />}
            {/* </div> */}
          </SheetContent>
        </Sheet>
      ) : (
        <div className="h-full flex-auto justify-center overflow-y-scroll px-4">
          {tab === "coins" && !!id && (
            <TokenPage tokenId={id as `0x${string}`} />
          )}
          {tab === "people" && !!id && (
            <div className="w-full max-w-3xl">
              <Profile userAddress={id as `0x${string}`} />
            </div>
          )}
          {tab === "ideas" && <CreateAToken />}
        </div>
      )}
      <Link
        to="/ideas/create"
        className="fixed bottom-4 right-4 z-50 rounded-full shadow-lg sm:hidden"
      >
        <Button
          variant="gradient"
          size="lg"
          className="h-14 w-14 rounded-full p-0 text-3xl"
        >
          +
        </Button>
      </Link>
    </div>
  );
};

export default index;
