import { routerAbi } from "@/ABIs/Router";
import { useToast } from "@/components/ui/use-toast";
import { Contracts } from "@/ContractsConfig";
import { useEffect } from "react";
import {
  ContractFunctionArgs,
  ContractFunctionExecutionError,
  ContractFunctionName,
  TransactionReceipt,
} from "viem";
import {
  useAccount,
  usePublicClient,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";

const countreAbi = [
  {
    type: "function",
    name: "current",
    inputs: [],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "decrement",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "increment",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "reset",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    name: "Count",
    inputs: [
      {
        name: "",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
] as const;

type FunctionNameType = ContractFunctionName<
  typeof routerAbi,
  "nonpayable" | "payable"
>;

const useContract = <
  Fn extends FunctionNameType,
  Args extends ContractFunctionArgs<
    typeof routerAbi,
    "nonpayable" | "payable",
    Fn
  >,
>({
  toastTitle,
  onSubmit,
  onConfirm,
  onConfirmDelayed,
}: {
  toastTitle: string;
  onSubmit?: (hash: `0x${string}`) => void;
  onConfirm?: (data: TransactionReceipt) => void;
  onConfirmDelayed?: (data: TransactionReceipt) => void;
}) => {
  const {
    writeContractAsync,
    data: txnHash,
    error,
    isPending: isSubmitting,
  } = useWriteContract();
  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    data,
  } = useWaitForTransactionReceipt({ hash: txnHash });
  const { toast } = useToast();
  const publicClient = usePublicClient({ chainId: 99888 });
  const account = useAccount();
  const callContract = async (functionName: Fn, args: Args, value?: bigint) => {
    toast({
      title: toastTitle,
      description: (
        <div className="flex items-baseline">
          Please confirm the transaction in your wallet
        </div>
      ),
    });
    // try {
    //   console.log("critical-log:1", functionName, args, value);
    //   const sim = await publicClient?.simulateContract({
    //     abi: countreAbi,
    //     address: "0xF461d09EB295f1538a6fec92072eB2F3578e121a",
    //     functionName: "increment",
    //     // @ts-expect-error - this is fine
    //     args: [],
    //     // @ts-expect-error - this is fine
    //     // value: value,
    //     account: account.address,
    //   });
    //   console.log("critical-log:2", sim);

    //   const hash = await writeContractAsync(sim?.request);
    //   console.log("critical-log:3", hash);
    //   const transaction = await publicClient?.waitForTransactionReceipt({
    //     hash: hash,
    //   });
    //   console.log("critical-log:5", transaction);

    //   if (onSubmit) onSubmit(hash);
    //   return hash;
    // } catch (e) {
    //   console.log("critical-log:4", e);
    // }
    try {
      console.log("critical-log:1", functionName, args, value);
      const sim = await publicClient?.simulateContract({
        abi: routerAbi,
        address: Contracts.router,
        functionName: functionName as FunctionNameType,
        // @ts-expect-error - this is fine
        args: args as Args,
        // @ts-expect-error - this is fine
        value: value,
        account: account.address,
      });
      console.log("critical-log:2", sim);

      const hash = await writeContractAsync({
        abi: routerAbi,
        address: Contracts.router,
        functionName: functionName as FunctionNameType,
        // @ts-expect-error - this is fine
        args: args as Args,
        // @ts-expect-error - this is fine
        value: value,
      });
      console.log("critical-log:3", hash);
      const transaction = await publicClient?.waitForTransactionReceipt({
        hash: hash,
      });
      console.log("critical-log:5", transaction);

      if (onSubmit) onSubmit(hash);
      return hash;
    } catch (e) {
      console.log("critical-log:4", e);
    }
  };

  useEffect(() => {
    if (error) {
      console.log({ ...error });
      const typedError = error as ContractFunctionExecutionError;
      let message = "";
      if (typedError.metaMessages && typedError.metaMessages.length > 2) {
        message = typedError.metaMessages[1].trim();
        if (message.startsWith("(") && message.endsWith(")")) {
          message = message.slice(1, -1);
        }
      }
      if (!message) {
        message = typedError.shortMessage || error.message;
      }
      toast({
        variant: "destructive",
        title: "Something went wrong",
        description: message,
      });
    }
  }, [error, toast]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (isConfirming) {
      toast({
        title: toastTitle,
        description: (
          <div className="flex items-baseline">
            Confirming transaction. Please wait.
          </div>
        ),
      });
    } else if (isConfirmed) {
      if (onConfirm) onConfirm(data);
      if (onConfirmDelayed) {
        timeoutId = setTimeout(() => {
          onConfirmDelayed(data);
        }, 2000);
      }
      toast({
        title: toastTitle,
        description: (
          <div className="flex items-baseline">
            Transaction confirmed.
            <br />
            {txnHash}
          </div>
        ),
      });
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isConfirmed, isConfirming, data]);

  return {
    callContract,
    isSubmitting,
    isConfirming,
    isConfirmed,
    toast,
    txnHash,
    data,
  };
};

export default useContract;
